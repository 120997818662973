import type { FC, PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { AuthProvider } from '../contexts/AuthContext';
import ThemeProvider from '../theme/ThemeProvider';


interface AppProviderProps { }

const AppProvider: FC<PropsWithChildren<AppProviderProps>> = ({ children }) => (
    <HelmetProvider>
        <StyledEngineProvider injectFirst>
            <BrowserRouter>
                <ThemeProvider>
                    <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }} autoHideDuration={3000}>
                        <AuthProvider>
                            {children}
                        </AuthProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </BrowserRouter>
        </StyledEngineProvider>
    </HelmetProvider>
);

export default AppProvider;
