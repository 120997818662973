import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Logo from 'src/components/Logo';
import { Outlet } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import useAuth from 'src/hooks/useAuth';
import { IconButton, Tooltip } from '@mui/material';

const AppBarStyled = styled(AppBar)(
    ({ theme }) => ({
        background: theme.header.background,
        margin: 0,
        position: 'fixed',
        zIndex: 1
    })
);

const ResponsiveAppBar = () => {
    const { logout } = useAuth();
    return (
        <>
            <AppBarStyled position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <Logo />
                            <Tooltip title="logout">
                                <IconButton onClick={logout}>
                                    <PowerSettingsNewIcon color="secondary" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBarStyled>
            <Box component="main" sx={{ p: 1 }}>
                <Box mt={7}>
                    <Outlet />
                </Box>
            </Box>
        </>

    );
}
export default ResponsiveAppBar;
