import { useRoutes } from 'react-router-dom';
import routes from './routes';
import useAuth from './hooks/useAuth';
import SplashScreen from 'src/components/SplashScreen';

const App = () => {
  const content = useRoutes(routes);
  const { isInitialized } = useAuth();

  return <div>{isInitialized ? content : <SplashScreen />}</div>;
};
export default App;
