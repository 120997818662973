import React, { createContext, ReactNode, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { authApi } from 'src/api';

const TOKEN_ID = "user-access-token";

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const initialAuthState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
};

type InitialiseAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    isAuthenticated: boolean;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
  payload?: {};
};

type Action = InitialiseAction | LoginAction | LogoutAction;

const setSession = (jwt: string | null): void => {
  if (jwt) {
    localStorage.setItem(TOKEN_ID, jwt);
    axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  } else {
    localStorage.setItem(TOKEN_ID, "");
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state: AuthState, action: InitialiseAction): AuthState => {
    const { isAuthenticated } = action.payload;
    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      };
  },
  LOGIN: (state: AuthState, action: LoginAction) => {
    return {
      ...state,
      isAuthenticated: true,
    };
  },
  LOGOUT: (state: AuthState) => ({
    ...state,
    isAuthenticated: false,
  }),
};

const reducer = (state: AuthState, action: Action): AuthState =>
  // @ts-ignore
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const initialize = async () => {
      try {
        const jwt = localStorage.getItem(TOKEN_ID);

        if (jwt && await authApi.validate(jwt)) {
          setSession(jwt);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
            }
          });
        } else {
          setSession(null);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const { token } = await authApi.login(email, password);

      setSession(token);
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
        }
      });
    } catch (err: any) {
      closeSnackbar();
      enqueueSnackbar(err.message || 'Unable to login', { variant: 'error' });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
