import type { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { Outlet } from 'react-router-dom';

interface AuthGuardProps {}

const AuthGuard: FC<PropsWithChildren<AuthGuardProps>> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children || <Outlet />}</>;
};

export default AuthGuard;
