import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class AuthApi extends ApiTokenSupport {
    async login(username: string, password: string): Promise<{ token: string; user_email: string }> {
        const resp = await axios.post<{ token: string, user_email: string  }>(
            `/api/auth/login`,
            { username, password },
        );
        return resp.data;
    }
    async validate(token: string): Promise<boolean> {
        try {
            const resp = await axios.post<boolean>(`api/auth/validate`, { token });
            return resp.data;
        } catch (e) {
            return false;
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthApi();
