import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { Order } from "src/types/order";

class OrderApi extends ApiTokenSupport {
    async fetch(status: string): Promise<Order[]> {
        const resp = await axios.get(
            `/api/orders/fetch/${status}`,
        );
        return resp.data;
    }

    async getOrder(id: number): Promise<Order> {
        const resp = await axios.get(`api/orders/retrieve/${id}`);
        return resp.data;
    }

    async packOrder(id: number): Promise<void> {
        const resp = await axios.post(`api/orders/print`, { id, action: 'pack' });
        return resp.data;
    }
    
    async print(id: number, targets: string[]): Promise<void> {
        const resp = await axios.post(`api/orders/print`, { id, action: 'print', targets });
        return resp.data;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OrderApi();
