import { Suspense, lazy, ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

import SplashScreen from './components/SplashScreen';
import GuestGuard from './guards/GuestGuard';
import AuthGuard from './guards/AuthGuard';
import DashboardLayout from './layouts/DashboardLayout';

export const Loader = (Component: ComponentType) => (props: { [key: string]: any }) =>
(
  <Suspense fallback={<SplashScreen />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loader(lazy(() => import('src/pages/Auth/Login')));
const Orders = Loader(lazy(() => import('src/pages/Orders')));
const OrderView = Loader(lazy(() => import('src/pages/Orders/OrderView')));

const routes = [
  {
    path: '/',
    children: [
      {
        path: '',
        element: <Navigate to="/login" replace />
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },

    ]
  },
  {
    path: '/orders',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Orders />
      },
      {
        path: 'pakk/:orderId',
        element: <OrderView />
      },
    ]
  },
];

export default routes;
